import { Injectable } from '@angular/core';
import { EngagementPath } from './data-layer.model';

/**
 * Google Tag Manager (GTM) service to send events for Google tag detection.
 */
@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  get windowRef(): any {
    return window;
  }

  constructor() {}

  private pushOnDataLayer(object: any) {
    if (object) {
      const dataLayer = this.windowRef.dataLayer;

      if (dataLayer) {
        dataLayer.push(object);
      }
    }
  }

  /**
   * User engagement by page path.
   *
   * @param url Page path.
   * @param title Page title (optional).
   */
  logPageView(url: string, title?: string) {
    const event: EngagementPath = {
      event: 'page_view',
      pagePath: url,
    };

    if (title) {
      event.pageTitle = title;
    }

    this.pushOnDataLayer(event);
  }

  /**
   * Log a custom event with an event, category, action, and label.
   */
  logEvent(event: string, category: string, action: string, label: string) {
    this.pushOnDataLayer({
      event,
      category,
      action,
      label,
    });
  }
}
