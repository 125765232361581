import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { DataLayerService } from './services/analytics/data-layer.service';
import { environment } from 'src/environments/environment';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'loan-application-form';

  constructor(
    private router: Router,
    private dataLayerService: DataLayerService,
    private titleService: Title
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Only trigger in production.
        if (environment.production) {
          this.dataLayerService.logPageView(
            event.url,
            this.titleService.getTitle()
          );

          fbq('track', 'PageView');
        }
      }
    });
  }
}
