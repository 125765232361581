import { Routes } from '@angular/router';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'loan',
    pathMatch: 'full',
  },
  {
    path: 'loan',
    loadChildren: () =>
      import('../../src/app/views/loan/loan.module').then((m) => m.LoanModule),
  },
  // {
  //   path: 'retail',
  //   loadChildren: () =>
  //     import('../../src/app/views/retail/retail.module').then(
  //       (m) => m.RetailModule
  //     ),
  // },
  {
    path: 'furniture',
    redirectTo: 'furniture/applicant',
    pathMatch: 'full',
  },
  {
    path: 'furniture/applicant',
    loadChildren: () =>
      import('../../src/app/views/furniture/furniture.module').then(
        (m) => m.FurnitureModule
      ),
  },
];

export default ROUTES;
